import React, { useState } from 'react';
// Data
import { tempBlogPosts } from '../../utils/data/BlogData';
// Components
import BlogPostPreview from './BlogPostPreview';
import SubHeaderComponent from '../headers/SubHeaderComponent';

function LatestBlogPostsComponent({ isCentered }) {
  const [latestBlogPosts, setLastestBlogPosts] = useState(tempBlogPosts.slice(0, 2));

  return (
    <section className='grid w-full h-full' aria-label='Latest Blog Posts'>
      <div className='grid grid-rows-reg gap-12 w-3/4 mx-auto mb-6'>
        <SubHeaderComponent
          isCentered={isCentered}
          title={'Get inspired by our tech blog'}
          subtitle={'Blog posts'}
        />

        <section className='grid lg:grid-cols-2 gap-8 h-full'>
          {/* Render Blog Posts */}
          {latestBlogPosts.map((post) => (
            <BlogPostPreview key={post.id} post={post} />
          ))}
        </section>
      </div>
    </section>
  );
}

export default LatestBlogPostsComponent;
